/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateProceedingDto
 */
export interface CreateUpdateProceedingDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProceedingDto
     */
    legalBasisId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProceedingDto
     */
    quantity: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateProceedingDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateProceedingDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProceedingDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProceedingDto
     */
    organizationalUnitId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProceedingDto
     */
    sampleId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProceedingDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProceedingDto
     */
    checkingProductId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProceedingDto
     */
    actNumber: string;
}

export function CreateUpdateProceedingDtoFromJSON(json: any): CreateUpdateProceedingDto {
    return CreateUpdateProceedingDtoFromJSONTyped(json, false);
}

export function CreateUpdateProceedingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateProceedingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'legalBasisId': json['legalBasisId'],
        'quantity': json['quantity'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'approved': json['approved'],
        'organizationalUnitId': json['organizationalUnitId'],
        'sampleId': json['sampleId'],
        'inspectedProductId': json['inspectedProductId'],
        'checkingProductId': json['checkingProductId'],
        'actNumber': json['actNumber'],
    };
}

export function CreateUpdateProceedingDtoToJSON(value?: CreateUpdateProceedingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'legalBasisId': value.legalBasisId,
        'quantity': value.quantity,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'approved': value.approved,
        'organizationalUnitId': value.organizationalUnitId,
        'sampleId': value.sampleId,
        'inspectedProductId': value.inspectedProductId,
        'checkingProductId': value.checkingProductId,
        'actNumber': value.actNumber,
    };
}


