/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMisstatementDto
 */
export interface GetMisstatementDto {
    /**
     * 
     * @type {string}
     * @memberof GetMisstatementDto
     */
    id: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetMisstatementDto
     */
    variance: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetMisstatementDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof GetMisstatementDto
     */
    other: string;
}

export function GetMisstatementDtoFromJSON(json: any): GetMisstatementDto {
    return GetMisstatementDtoFromJSONTyped(json, false);
}

export function GetMisstatementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMisstatementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'variance': GetDictionaryDtoFromJSON(json['variance']),
        'inspectedProductId': json['inspectedProductId'],
        'other': json['other'],
    };
}

export function GetMisstatementDtoToJSON(value?: GetMisstatementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'variance': GetDictionaryDtoToJSON(value.variance),
        'inspectedProductId': value.inspectedProductId,
        'other': value.other,
    };
}


