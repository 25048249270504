/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdatePakDto
 */
export interface CreateUpdatePakDto {
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdatePakDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdatePakDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePakDto
     */
    penaltyAmount: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePakDto
     */
    quantity: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePakDto
     */
    paidAmount: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdatePakDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePakDto
     */
    organizationalUnitId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePakDto
     */
    proceedingId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePakDto
     */
    sampleId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePakDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePakDto
     */
    productTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePakDto
     */
    actNumber: string;
}

export function CreateUpdatePakDtoFromJSON(json: any): CreateUpdatePakDto {
    return CreateUpdatePakDtoFromJSONTyped(json, false);
}

export function CreateUpdatePakDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePakDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'penaltyAmount': json['penaltyAmount'],
        'quantity': json['quantity'],
        'paidAmount': json['paidAmount'],
        'approved': json['approved'],
        'organizationalUnitId': json['organizationalUnitId'],
        'proceedingId': json['proceedingId'],
        'sampleId': json['sampleId'],
        'inspectedProductId': json['inspectedProductId'],
        'productTypeId': json['productTypeId'],
        'actNumber': json['actNumber'],
    };
}

export function CreateUpdatePakDtoToJSON(value?: CreateUpdatePakDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'penaltyAmount': value.penaltyAmount,
        'quantity': value.quantity,
        'paidAmount': value.paidAmount,
        'approved': value.approved,
        'organizationalUnitId': value.organizationalUnitId,
        'proceedingId': value.proceedingId,
        'sampleId': value.sampleId,
        'inspectedProductId': value.inspectedProductId,
        'productTypeId': value.productTypeId,
        'actNumber': value.actNumber,
    };
}


