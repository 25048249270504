import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import { Organization } from '../../../common/snrwbCore/contexts/OrganizationContext';
import * as ProductTypeContext from '../../../common/snrwbCore/contexts/ProductTypeContext';
import { ProductType } from '../ProductTypes/ProductType';

const NewAlert = (props: { show: boolean }) => (
  <>
    {props.show && (
      <Alert variant="warning" className="mt-3">
        <Alert.Heading>Uwaga!</Alert.Heading>
        <p>Nie ma typu wyrobu o takich danych - zostanie utworzony nowy</p>
      </Alert>
    )}
  </>
);

const ClearButton = (props: { show: boolean; onClick: () => void }) => (
  <>
    {props.show && (
      <div className="d-flex justify-content-end mt-3">
        <Button variant="outline-dark" onClick={props.onClick}>
          Wyczyść
        </Button>
      </div>
    )}
  </>
);

interface ProductTypeSelectorProps {
  productType: ProductTypeContext.ProductType;
  producer: Organization;
  showValidationErrors: boolean;
  onSelect: (productType: ProductTypeContext.ProductType) => void;
  onValidation: (status: boolean) => void;
  onClear: () => void;
}

export const ProductTypeSelector: React.FC<ProductTypeSelectorProps> =
  props => {
    const [showNewAlert, setShowNewAlert] = useState(false);
    const [selected, setSelected] = useState(false);
    const [productType, setProductType] = useState(props.productType);
    const [readOnly, setReadOnly] = useState(false);

    const decision = (newProduct: boolean) => {
      setShowNewAlert(newProduct);
      setSelected(true);
    };

    const clear = () => {
      setSelected(false);
      setShowNewAlert(false);
      setProductType(ProductTypeContext.newProductType());
      setReadOnly(false);
      props.onClear();
    };

    useEffect(() => {
      setProductType(props.productType);
      setReadOnly('id' in props.productType);
    }, [props.productType]);

    return (
      <>
        <ProductType
          readOnly={readOnly}
          showValidationErrors={props.showValidationErrors}
          producer={props.producer}
          onChange={props.onSelect}
          onValidation={props.onValidation}
          onDecision={decision}
          value={productType}
          selected={selected}
        />
        <NewAlert show={showNewAlert} />
        <ClearButton show={selected} onClick={clear} />
      </>
    );
  };
