/*eslint max-lines-per-function: ["error", 220]*/
import { useHistory } from 'react-router-dom';
import { matchPath } from 'react-router';
import * as Icon from 'react-bootstrap-icons';

import { LandingView } from '../snrwb/containers/LandingView/LandingView';
import { DocumentationView } from '../app/containers/DocumentationView';
import { InspectionsView } from '../snrwb/containers/InspectionsView/InspectionsView';
import { InspectionView } from '../snrwb/containers/InspectionsView/InspectionView';
import { SampleExamsView } from '../snrwb/containers/SampleExamsView/SampleExamsView';
import { SampleExamView } from '../snrwb/containers/SampleExamsView/SampleExamView';
import { PublishingView } from '../snrwb/containers/PublishingView/PublishingView';
import { ProceedingsView } from '../snrwb/containers/ProceedingsView/ProceedingsView';
import { ProceedingView } from '../snrwb/containers/ProceedingsView/ProceedingView';
import { PaksView } from '../snrwb/containers/PaksView/PaksView';
import { PakView } from '../snrwb/containers/PaksView/PakView';
import { DictionariesTreeView } from '../snrwb/containers/DictionariesView/DictionariesTreeView';
import { OrganizationalUnitsTreeView } from '../snrwb/containers/OrganizationalUnitsView/OrganizationalUnitsTreeView';
import { CertificatesView } from '../snrwb/containers/CertificatesView/CertificatesView';
import { ReportConductedInspectionsView } from '../snrwb/containers/Reports/ReportsByInspectionView/ReportConductedInspectionsView';
import { ReportInspectedProductsView } from '../snrwb/containers/Reports/ReportsByInspectionView/ReportInspectedProductsView';
import { ReportInspectedProductsCPRView } from '../snrwb/containers/Reports/ReportsByInspectionView/ReportInspectedProductsCPRView';
import { ReportConductedInspectionsCPRView } from '../snrwb/containers/Reports/ReportsByInspectionView/ReportConductedInspectionsCPRView';
import { ReportInspectedOrganizationView } from '../snrwb/containers/Reports/ReportsByOrganizationView/ReportInspectedOrganizationView';
import { ReportInspectedOrganizationCPRView } from '../snrwb/containers/Reports/ReportsByOrganizationView/ReportInspectedOrganizationCPRView';
import { ReportInspectedOrganizationByProductsView } from '../snrwb/containers/Reports/ReportsByOrganizationView/ReportInspectedOrganizationByProductsView';
import { ReportInspectedOrganizationByProductsCPRView } from '../snrwb/containers/Reports/ReportsByOrganizationView/ReportInspectedOrganizationByProductsCPRView';
import { ReportInspectedOrganizationByProductsWithMisstatementView } from '../snrwb/containers/Reports/ReportsByOrganizationView/ReportInspectedOrganizationByProductsWithMisstatementView';
import { ReportInspectedOrganizationByProductsWithMisstatementCPRView } from '../snrwb/containers/Reports/ReportsByOrganizationView/ReportInspectedOrganizationByProductsWithMisstatementCPRView';
import { ReportInspectedProductsBySignView } from '../snrwb/containers/Reports/ReportsInspectedProductsView/ReportInspectedProductsBySignView';
import { ReportInspectedProductsByTypeView } from '../snrwb/containers/Reports/ReportsInspectedProductsView/ReportInspectedProductsByTypeView';
import { ReportsView } from '../snrwb/containers/Reports/ReportsView';
import { ReportInspectedProductsByRangeView } from '../snrwb/containers/Reports/ReportsInspectedProductsView/ReportInspectedProductsByRangeView';
import { ReportInspectedProductsByMisstatementIView } from '../snrwb/containers/Reports/ReportsInspectedProductsView/ReportInspectedProductsByMisstatementIView';
import { ReportInspectedProductsByMisstatementIIIView } from '../snrwb/containers/Reports/ReportsInspectedProductsView/ReportInspectedProductsByMisstatementIIIView';
import { ReportInspectedProductsByMisstatementIIView } from '../snrwb/containers/Reports/ReportsInspectedProductsView/ReportInspectedProductsByMisstatementIIView';
import { EmployeesView } from '../snrwb/containers/EmployeesView/EmployeesView';
import { InspectionPlanView } from '../snrwb/containers/InspectionPlansView/InspectionPlansView';
import { OrganizationsView } from '../snrwb/containers/OrganizationView/OrganizationsView';
import { InspectionPlanDetailsView } from '../snrwb/containers/InspectionPlansView/InspectionPlanDetailsView';
import { OrganizationView } from '../snrwb/containers/OrganizationView/OrganizationView';
import { CustomsFeedbacksView } from '../snrwb/containers/CustomsFeedbacksView/CustomsFeedbacksView';
import { ReportSampleExamAView } from '../snrwb/containers/Reports/ReportsBySampleExamView/ReportSampleExamAView';
import { ReportSampleExamBView } from '../snrwb/containers/Reports/ReportsBySampleExamView/ReportSampleExamBView';
import { ReportSampleExamsListView } from '../snrwb/containers/Reports/ReportsBySampleExamView/ReportSampleExamsListView';
import { ReportJudgmentsCountView } from '../snrwb/containers/Reports/ReportsJudgmentsView/ReportJudgmentsCountView';
import { ReportCustomsFeedbackView } from '../snrwb/containers/Reports/ReportsCustomsFeedbackView/ReportCustomsFeedbackView';
import { ReportCorrectiveActionView } from '../snrwb/containers/Reports/ReportsByCorrectiveActionView/ReportCorrectiveActionView';
import { ProductTypesView } from '../snrwb/containers/ProductTypesView/ProductTypesView';
import { ProductTypeView } from '../snrwb/containers/ProductTypesView/ProductTypeView';
import { TimelineView } from '../snrwb/containers/ProductTypesView/TimelineView';
import { SampleExamTimelineView } from '../snrwb/containers/SampleExamsView/SampleExamTimelineView';
import { SampleExamResultTimelineView } from '../snrwb/containers/SampleExamsView/SampleExamResultTimelineView';
import { ReportPakView } from '../snrwb/containers/Reports/ReportsPakView/ReportPakView';
import { ReportProceedingView } from '../snrwb/containers/Reports/ReportProceedingView/ReportProceedingView';
import { ReportProceedingCprView } from '../snrwb/containers/Reports/ReportProceedingView/ReportProceedingCprView';
import { ReportJudgmentsKindView } from '../snrwb/containers/Reports/ReportsJudgmentsView/ReportJudgmentsKindView';
import { KwzsView } from '../snrwb/containers/KwzsView/KwzsView';
import { KwzDetailsView } from '../snrwb/containers/KwzsView/KwzDetailsView';
import { KwzTimelineView } from '../snrwb/containers/KwzsView/KwzTimelineView';
import { ReportMisstatementByProductGroupView } from '../snrwb/containers/Reports/ReportsMisstatementByProductGroupView/ReportMisstatementByProductGroupView';
import { ReportPakAmountView } from '../snrwb/containers/Reports/ReportsPakView/ReportPakAmountView';
import { ReportCostsSampleExamsView } from '../snrwb/containers/Reports/ReportCostsSampleExamsView/ReportCostsSampleExamsView';
import { ReportInspectionPlanByOrganizationView } from '../snrwb/containers/Reports/ReportInspectionPlanByOrganizationView/ReportInspectionPlanByOrganizationView';
import { ReportInspectionPlanByInspectedProductView } from '../snrwb/containers/Reports/ReportInspectionPlanByOrganizationView/ReportInspectionPlanByInspectedProductView';
import { InspectionPlanProgressView } from '../snrwb/containers/InspectionPlansView/InspectionPlansProgressView';
import { SampleCollectView } from '../snrwb/containers/SampleExamsView/SampleCollectView';
import { ReportPublicationsView } from '../snrwb/containers/Reports/ReportPublicationsView/ReportsPublicationsView';
import { MerginOrganizationsView } from '../snrwb/containers/OrganizationView/MerginOrganizationsView';
import { MerginProductTypesView } from '../snrwb/containers/ProductTypesView/MerginProductTypesView';

import { Role } from './snrwbCore/authorization/snrwbAuthorizationRoles';
import { useAuth } from './hooks/useAuth';
import { useNotifications } from './hooks/useNotifications';

export interface MenuItem {
  heading: string;
  path: string;
  icon?: Icon.Icon;
  view: React.ComponentType;
  exact: boolean;
  requiresAnyOf: Role[];
  submenu?: MenuItem[];
  certificatesOnly?: boolean;
  submenuAlwaysVisible?: boolean;
}

export const routesInMenu: MenuItem[] = [
  {
    heading: 'Kontrole',
    path: '/kontrole',
    icon: Icon.CardList,
    view: InspectionsView,
    exact: true,
    requiresAnyOf: [Role.InspectionsView],
    submenu: [],
  },
  {
    heading: 'Badania',
    path: '/badania',
    icon: Icon.Search,
    view: SampleExamsView,
    exact: true,
    requiresAnyOf: [Role.ExamsView],
    submenu: [],
  },
  {
    heading: 'Publikacje badań',
    path: '/publikacje',
    icon: Icon.Megaphone,
    view: PublishingView,
    exact: true,
    requiresAnyOf: [Role.ExamsPublishing],
    submenu: [],
  },
  {
    heading: 'Postępowania',
    path: '/postepowania',
    icon: Icon.Archive,
    view: ProceedingsView,
    exact: true,
    requiresAnyOf: [Role.ProceedingsView],
    submenu: [
      {
        heading: 'W sprawie nałożenia kary',
        path: '/postepowania/pak',
        view: PaksView,
        exact: true,
        requiresAnyOf: [Role.PakView],
      },
    ],
  },
  {
    heading: 'Certyfikaty',
    path: '/certyfikaty',
    icon: Icon.Award,
    view: CertificatesView,
    requiresAnyOf: [Role.CertificatesView],
    exact: true,
    certificatesOnly: true,
  },
  {
    heading: 'Opinie celne',
    path: '/opinieCelne',
    icon: Icon.Binoculars,
    view: CustomsFeedbacksView,
    requiresAnyOf: [Role.CustomsFeedbacksView],
    exact: true,
  },
  {
    heading: 'Typy wyrobów',
    path: '/typy-wyrobow',
    icon: Icon.Bricks,
    view: ProductTypesView,
    exact: true,
    requiresAnyOf: [Role.ProductTypesView],
    submenu: [],
  },
  {
    heading: 'Podmioty',
    path: '/podmioty',
    icon: Icon.People,
    view: OrganizationsView,
    exact: true,
    requiresAnyOf: [Role.OrganizationsView],
    submenu: [],
  },
  {
    heading: 'Plany kontroli',
    path: '/plany',
    icon: Icon.Pencil,
    view: InspectionPlanView,
    exact: true,
    requiresAnyOf: [Role.InspectionsPlansView],
    submenu: [
      {
        heading: 'Realizacja planów kontroli',
        path: '/plany/postepy',
        view: InspectionPlanProgressView,
        exact: true,
        requiresAnyOf: [Role.InspectionsPlansView],
      },
    ],
  },
  {
    heading: 'Zakwestionowane wyroby',
    path: '/kwz',
    icon: Icon.ExclamationDiamond,
    view: KwzsView,
    exact: true,
    requiresAnyOf: [Role.KwzView],
    submenu: [],
  },
  {
    heading: 'Raporty',
    path: '/raporty',
    icon: Icon.List,
    view: ReportsView,
    exact: true,
    requiresAnyOf: [Role.Reports],
    submenu: [
      {
        heading: 'Analiza typów wyrobu',
        path: '/raporty/wyroby-analiza-typow-wyrobu',
        view: ReportInspectedProductsByTypeView,
        exact: true,
        requiresAnyOf: [Role.Reports],
      },
    ],
    submenuAlwaysVisible: true,
  },
  {
    heading: 'Konfiguracja',
    path: '/konfiguracja',
    icon: Icon.Gear,
    view: DictionariesTreeView,
    exact: true,
    requiresAnyOf: [Role.ConfigurationView],
    submenu: [
      {
        heading: 'Słowniki',
        path: '/konfiguracja/slowniki',
        view: DictionariesTreeView,
        requiresAnyOf: [Role.ConfigurationView],
        exact: true,
      },
      {
        heading: 'Pracownicy',
        path: '/konfiguracja/pracownicy',
        view: EmployeesView,
        requiresAnyOf: [Role.ConfigurationView],
        exact: true,
      },
      {
        heading: 'Organy',
        path: '/konfiguracja/organy',
        view: OrganizationalUnitsTreeView,
        requiresAnyOf: [Role.ConfigurationView],
        exact: true,
      },
    ],
  },
  {
    heading: 'Pomoc',
    path: '/pomoc',
    icon: Icon.InfoCircle,
    view: DocumentationView,
    exact: true,
    requiresAnyOf: [Role.Login],
    submenu: [],
    certificatesOnly: true,
  },
];

export const routesOutsideMenu: MenuItem[] = [
  {
    heading: 'Strona główna',
    path: '/',
    view: LandingView,
    exact: true,
    requiresAnyOf: [Role.Login],
    certificatesOnly: true,
  },
  {
    heading: 'Kontrole',
    path: '/kontrole/podglad/:id/:inspectedProductId/:bannerId',
    view: InspectionView,
    exact: true,
    requiresAnyOf: [Role.InspectionsEdit],
  },
  {
    heading: 'Kontrole',
    path: '/kontrole/podglad/:id',
    view: InspectionView,
    exact: true,
    requiresAnyOf: [Role.InspectionsEdit],
  },
  {
    heading: 'Badania',
    path: '/badania/podglad/:id/:sampleId/:bannerId',
    view: SampleExamView,
    exact: true,
    requiresAnyOf: [Role.ExamsEdit],
  },
  {
    heading: 'Badania',
    path: '/badania/podglad/:id',
    view: SampleExamView,
    exact: true,
    requiresAnyOf: [Role.ExamsEdit],
  },
  {
    heading: 'Badania',
    path: '/badania/pobranie/:id/:tab',
    view: SampleCollectView,
    exact: true,
    requiresAnyOf: [Role.ExamsEdit],
  },
  {
    heading: 'Badania',
    path: '/badania/pobranie/:id',
    view: SampleCollectView,
    exact: true,
    requiresAnyOf: [Role.ExamsEdit],
  },
  {
    heading: 'Badania',
    path: '/badania/probka/:id/:sampleId',
    view: SampleExamView,
    exact: true,
    requiresAnyOf: [Role.ExamsEdit],
  },
  {
    heading: 'Badania',
    path: '/badania/publikacja/:id/:findSample',
    view: SampleExamView,
    exact: true,
    requiresAnyOf: [Role.ExamsEdit],
  },
  {
    heading: 'Badania',
    path: '/badania/os-czasu/:id',
    view: SampleExamTimelineView,
    exact: true,
    requiresAnyOf: [Role.ExamsEdit],
  },
  {
    heading: 'Postępowania',
    path: '/postepowania/podglad/:id/:bannerId',
    view: ProceedingView,
    exact: true,
    requiresAnyOf: [Role.ProceedingsEdit],
  },
  {
    heading: 'Postępowania',
    path: '/postepowania/podglad/:id',
    view: ProceedingView,
    exact: true,
    requiresAnyOf: [Role.ProceedingsEdit],
  },
  {
    heading: 'Postępowania w sprawie nałożenia kary',
    path: '/postepowania/pak/podglad/:id/:bannerId',
    view: PakView,
    exact: true,
    requiresAnyOf: [Role.PakEdit],
  },
  {
    heading: 'Postępowania w sprawie nałożenia kary',
    path: '/postepowania/pak/podglad/:id',
    view: PakView,
    exact: true,
    requiresAnyOf: [Role.PakEdit],
  },
  {
    heading: 'Plany kontroli',
    path: '/plany/podglad/:id',
    view: InspectionPlanDetailsView,
    exact: true,
    requiresAnyOf: [Role.InspectionsPlansEdit],
  },
  {
    heading: 'Zakwestionowane wyroby',
    path: '/kwz/podglad/:id',
    view: KwzDetailsView,
    exact: true,
    requiresAnyOf: [Role.InspectionsPlansEdit],
  },
  {
    heading: 'Zakwestionowane wyroby',
    path: '/kwz/os-czasu/:id',
    view: KwzTimelineView,
    exact: true,
    requiresAnyOf: [Role.InspectionsPlansView],
  },
  {
    heading: 'Przeprowadzone kontrole',
    path: '/raporty/przeprowadzone-kontrole',
    view: ReportConductedInspectionsView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Skontrolowane wyroby',
    path: '/raporty/skontrolowane-wyroby',
    view: ReportInspectedProductsView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Przeprowadzone kontrole CPR',
    path: '/raporty/przeprowadzone-kontrole-cpr',
    view: ReportConductedInspectionsCPRView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Skontrolowane wyroby CPR',
    path: '/raporty/skontrolowane-wyroby-cpr',
    view: ReportInspectedProductsCPRView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Kontrole u podmiotów',
    path: '/raporty/kontrole-podmiotow',
    view: ReportInspectedOrganizationView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Wyroby skontrolowane u podmiotów',
    path: '/raporty/wyroby-skontrolowane-podmiotow',
    view: ReportInspectedOrganizationByProductsView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Skontrolowane niezgodne wyroby u podmiotów ',
    path: '/raporty/wyroby-niezgodne-podmiotow',
    view: ReportInspectedOrganizationByProductsWithMisstatementView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Kontrole CPR u podmiotów',
    path: '/raporty/kontrole-cpr-podmiotow',
    view: ReportInspectedOrganizationCPRView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Skontrolowane wyroby CPR u podmiotów',
    path: '/raporty/wyroby-cpr-skontrolowane-podmiotow',
    view: ReportInspectedOrganizationByProductsCPRView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Skontrolowane niezgodne wyroby CPR u podmiotów',
    path: '/raporty/wyroby-niezgodne-CPR-podmiotow',
    view: ReportInspectedOrganizationByProductsWithMisstatementCPRView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Skontrolowane wyroby wg oznakowania',
    path: '/raporty/wyroby-wg-oznakowania',
    view: ReportInspectedProductsBySignView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Zakres przeprowadzonych kontroli',
    path: '/raporty/wyroby-wg-zakresu',
    view: ReportInspectedProductsByRangeView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Rodzaje stwierdzonych nieprawidłowości - grupa I',
    path: '/raporty/wyroby-wg-nieprawidlowosci-I',
    view: ReportInspectedProductsByMisstatementIView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Rodzaje stwierdzonych nieprawidłowości - grupa II',
    path: '/raporty/wyroby-wg-nieprawidlowosci-II',
    view: ReportInspectedProductsByMisstatementIIView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Rodzaje stwierdzonych nieprawidłowości - grupa III',
    path: '/raporty/wyroby-wg-nieprawidlowosci-III',
    view: ReportInspectedProductsByMisstatementIIIView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Badania próbek wyrobów budowlanych',
    path: '/raporty/badania-probek-a',
    view: ReportSampleExamAView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Badania próbek wyrobów budowlanych CPR',
    path: '/raporty/badania-probek-b',
    view: ReportSampleExamBView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Pobrane próbki kontrolne',
    path: '/raporty/badania-probek-c',
    view: ReportSampleExamsListView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Liczba wydanych postanowień',
    path: '/raporty/wydane-postanowienia',
    view: ReportJudgmentsCountView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Współpraca z organami celnymi',
    path: '/raporty/organy-celne',
    view: ReportCustomsFeedbackView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Działania naprawcze, wykonanie nakazów',
    path: '/raporty/dzialania-naprawcze',
    view: ReportCorrectiveActionView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Administracyjne kary pieniężne',
    path: '/raporty/kary-pieniezne',
    view: ReportPakView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Nałożone kary pieniężne',
    path: '/raporty/nalozone-kary-pieniezne',
    view: ReportPakAmountView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Liczba postępowań administracyjnych',
    path: '/raporty/postepowania-administracyjne',
    view: ReportProceedingView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Liczba postępowań administracyjnych dot. wyrobów CPR',
    path: '/raporty/postepowania-administracyjne-cpr',
    view: ReportProceedingCprView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading:
      'Wydane orzeczenia w postępowaniach administracyjnych wszczętych na podstawie uowb',
    path: '/raporty/wydane-orzeczenia',
    view: ReportJudgmentsKindView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Nieprawidłowości w grupach wyrobów',
    path: '/raporty/nieprawidlowosci-wg-grup',
    view: ReportMisstatementByProductGroupView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Budżet, koszty',
    path: '/raporty/budzet-koszty',
    view: ReportCostsSampleExamsView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Wykonanie planu - kontrole planowe u podmiotów',
    path: '/raporty/plan-kontroli-podmiotow',
    view: ReportInspectionPlanByOrganizationView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Wykonanie planu - liczba skontrolowanych wyrobów',
    path: '/raporty/plan-kontroli-wyrobow',
    view: ReportInspectionPlanByInspectedProductView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Opublikowane próbki',
    path: '/raporty/publikacje-probek',
    view: ReportPublicationsView,
    exact: true,
    requiresAnyOf: [Role.Reports],
  },
  {
    heading: 'Podmioty',
    path: '/podmioty/podglad/:id/:active',
    view: OrganizationView,
    exact: true,
    requiresAnyOf: [Role.OrganizationsEdit],
  },
  {
    heading: 'Podmioty',
    path: '/podmioty/podglad/:id',
    view: OrganizationView,
    exact: true,
    requiresAnyOf: [Role.OrganizationsEdit],
  },
  {
    heading: 'Podmioty - sklejanie',
    path: '/podmioty/sklejanie/:orgDeleteId/:orgStayId',
    view: MerginOrganizationsView,
    exact: true,
    requiresAnyOf: [Role.OrganizationsMerging],
  },
  {
    heading: 'Typy wyrobów',
    path: '/typy-wyrobow/os-czasu/:id',
    view: TimelineView,
    exact: true,
    requiresAnyOf: [Role.ProductTypesView],
  },
  {
    heading: 'Typy wyrobów - sklejanie',
    path: '/typy-wyrobow/sklejanie/:prodDeleteId/:prodStayId',
    view: MerginProductTypesView,
    exact: true,
    requiresAnyOf: [Role.ProductTypesMerging],
  },
  {
    heading: 'Typy wyrobów',
    path: '/typy-wyrobow/podglad/:id',
    view: ProductTypeView,
    exact: true,
    requiresAnyOf: [Role.ProductTypesView],
  },
  {
    heading: 'Publikacje badań',
    path: '/publikacje/:id/os-czasu',
    view: SampleExamResultTimelineView,
    exact: true,
    requiresAnyOf: [Role.ExamsEdit],
  },
  {
    heading: 'Strona główna',
    path: '*',
    view: LandingView,
    exact: true,
    requiresAnyOf: [Role.Login],
  },
];

export const currentMenuItems = (activePage: string) => {
  const items: MenuItem[] = [routesOutsideMenu[0]];

  for (const position of routesInMenu) {
    if (matchPath(activePage, position.path)) {
      items.push(position);
    }

    for (const subposition of position.submenu || []) {
      if (matchPath(activePage, subposition.path)) {
        items.push(subposition);
      }
    }
  }

  return items;
};

export const useNavigation = () => {
  const history = useHistory();
  const actions = (url: string) => history.push(url);

  const auth = useAuth();
  const notifications = useNotifications();

  const goWith = (role: Role, url: string, newWindow?: boolean) => {
    if (!auth.check(role)) {
      notifications.unauthorized();
      return;
    }
    if (!newWindow) {
      actions(url);
    } else {
      window.open(url, '_blank');
    }
  };

  const doc = (documentId?: string) => {
    if (!documentId) {
      return '';
    }
    return `/${documentId}`;
  };

  return {
    menuItem: (location: string) => actions(location),
    inspection: (
      id: string,
      newWindow?: boolean,
      inspectedProductId?: string,
      bannerId?: string,
    ) =>
      goWith(
        Role.InspectionsView,
        `/kontrole/podglad/${id}` + doc(inspectedProductId) + doc(bannerId),
        newWindow,
      ),
    inspectionPlan: (id: string, newWindow?: boolean) =>
      goWith(Role.InspectionsPlansView, `/plany/podglad/${id}`, newWindow),
    allInspectionPlans: () => goWith(Role.InspectionsPlansView, '/plany'),
    myInspections: () => goWith(Role.InspectionsView, '/kontrole'),
    sampleCollect: (id: string, newWindow?: boolean, tab?: string) =>
      goWith(Role.ExamsView, `/badania/pobranie/${id}` + doc(tab), newWindow),
    sampleExam: (
      id: string,
      newWindow?: boolean,
      sampleId?: string,
      bannerId?: string,
    ) =>
      goWith(
        Role.ExamsView,
        `/badania/podglad/${id}` + doc(sampleId) + doc(bannerId),
        newWindow,
      ),
    sampleExamPublishing: (id: string, newWindow?: boolean) =>
      goWith(Role.ExamsPublishing, `/badania/publikacja/${id}/true`, newWindow),
    mySampleCollects: () => goWith(Role.ExamsView, '/badania'),
    proceeding: (id: string, newWindow?: boolean, bannerId?: string) =>
      goWith(
        Role.ProceedingsView,
        `/postepowania/podglad/${id}` + doc(bannerId),
        newWindow,
      ),
    myProceedings: () => goWith(Role.ProceedingsView, '/postepowania'),
    pak: (id: string, newWindow?: boolean, bannerId?: string) =>
      goWith(
        Role.PakView,
        `/postepowania/pak/podglad/${id}` + doc(bannerId),
        newWindow,
      ),
    myPaks: () => goWith(Role.PakView, '/postepowania/pak'),
    help: () => actions('/pomoc'),
    report1: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/przeprowadzone-kontrole', newWindow),
    report2: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/skontrolowane-wyroby', newWindow),
    report3: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/przeprowadzone-kontrole-cpr', newWindow),
    report4: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/skontrolowane-wyroby-cpr', newWindow),
    report5: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/kontrole-podmiotow', newWindow),
    report6: (newWindow?: boolean) =>
      goWith(
        Role.Reports,
        '/raporty/wyroby-skontrolowane-podmiotow',
        newWindow,
      ),
    report7: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/wyroby-niezgodne-podmiotow', newWindow),
    report8: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/kontrole-cpr-podmiotow', newWindow),
    report9: (newWindow?: boolean) =>
      goWith(
        Role.Reports,
        '/raporty/wyroby-cpr-skontrolowane-podmiotow',
        newWindow,
      ),
    report10: (newWindow?: boolean) =>
      goWith(
        Role.Reports,
        '/raporty/wyroby-niezgodne-CPR-podmiotow',
        newWindow,
      ),
    report11: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/wyroby-wg-oznakowania', newWindow),
    report12: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/wyroby-wg-zakresu', newWindow),
    report13I: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/wyroby-wg-nieprawidlowosci-I', newWindow),
    report13II: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/wyroby-wg-nieprawidlowosci-II', newWindow),
    report13III: (newWindow?: boolean) =>
      goWith(
        Role.Reports,
        '/raporty/wyroby-wg-nieprawidlowosci-III',
        newWindow,
      ),
    report14a: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/badania-probek-a', newWindow),
    report14b: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/badania-probek-b', newWindow),
    report14c: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/badania-probek-c', newWindow),
    report15: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/wydane-postanowienia', newWindow),
    report16a: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/postepowania-administracyjne', newWindow),
    report16b: (newWindow?: boolean) =>
      goWith(
        Role.Reports,
        '/raporty/postepowania-administracyjne-cpr',
        newWindow,
      ),
    report17: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/wydane-orzeczenia', newWindow),
    report18: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/dzialania-naprawcze', newWindow),
    report19: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/organy-celne', newWindow),
    report20: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/nieprawidlowosci-wg-grup', newWindow),
    report21a: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/kary-pieniezne', newWindow),
    report21b: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/nalozone-kary-pieniezne', newWindow),
    report22: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/budzet-koszty', newWindow),
    reportInspectionPlanByOrganization: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/plan-kontroli-podmiotow', newWindow),
    reportInspectionPlanByInspectedProduct: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/plan-kontroli-wyrobow', newWindow),
    reportPublications: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/publikacje-probek', newWindow),
    timeline: (id: string, newWindow?: boolean) =>
      goWith(Role.ProductTypesView, `/typy-wyrobow/os-czasu/${id}`, newWindow),
    timelineSampleExam: (id: string, newWindow?: boolean) =>
      goWith(Role.ProductTypesView, `/badania/os-czasu/${id}`, newWindow),
    timelineExamResult: (id: string, newWindow?: boolean) =>
      goWith(Role.ProductTypesView, `/publikacje/${id}/os-czasu`, newWindow),
    timelineKwz: (id: string, newWindow?: boolean) =>
      goWith(Role.KwzView, `/kwz/os-czasu/${id}`, newWindow),
    organization: (id: string, newWindow?: boolean, active?: string) =>
      goWith(
        Role.OrganizationsView,
        active
          ? `/podmioty/podglad/${id}/${active}`
          : `/podmioty/podglad/${id}`,
        newWindow,
      ),
    merginOrganizations: (
      orgDeleteId: string,
      newWindow?: boolean,
      orgStayId?: string,
    ) =>
      goWith(
        Role.OrganizationsMerging,
        `/podmioty/sklejanie/${orgDeleteId}/${orgStayId}`,
        newWindow,
      ),
    productTypes: () => goWith(Role.ProductTypesView, `/typy-wyrobow`),
    productType: (id: string, newWindow?: boolean) =>
      goWith(Role.ProductTypesView, `/typy-wyrobow/podglad/${id}`, newWindow),
    merginProductTypes: (
      prodDeleteId: string,
      newWindow?: boolean,
      prodStayId?: string,
    ) =>
      goWith(
        Role.ProductTypesMerging,
        `/typy-wyrobow/sklejanie/${prodDeleteId}/${prodStayId}`,
        newWindow,
      ),
    reload: () => history.go(0),
    kwz: (id: string, newWindow?: boolean) =>
      goWith(Role.KwzView, `/kwz/podglad/${id}`, newWindow),
    myKwzs: () => goWith(Role.KwzView, '/kwz'),
    sample: (sampleExamId: string, sampleId: string, newWindow?: boolean) =>
      goWith(
        Role.ExamsView,
        `/badania/probka/${sampleExamId}/${sampleId}`,
        newWindow,
      ),
    certificates: () => goWith(Role.CertificatesView, '/certyfikaty'),
    reportProductTypes: (newWindow?: boolean) =>
      goWith(Role.Reports, '/raporty/wyroby-analiza-typow-wyrobu', newWindow),
  };
};
