/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetInspectedProductDto,
    GetInspectedProductDtoFromJSON,
    GetInspectedProductDtoFromJSONTyped,
    GetInspectedProductDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
    GetSampleDto,
    GetSampleDtoFromJSON,
    GetSampleDtoFromJSONTyped,
    GetSampleDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProceedingWithPartiesDto
 */
export interface GetProceedingWithPartiesDto {
    /**
     * 
     * @type {string}
     * @memberof GetProceedingWithPartiesDto
     */
    id: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetProceedingWithPartiesDto
     */
    legalBasis: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetProceedingWithPartiesDto
     */
    quantity: string;
    /**
     * 
     * @type {Date}
     * @memberof GetProceedingWithPartiesDto
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetProceedingWithPartiesDto
     */
    endDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetProceedingWithPartiesDto
     */
    approved: boolean;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetProceedingWithPartiesDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {GetSampleDto}
     * @memberof GetProceedingWithPartiesDto
     */
    sample: GetSampleDto;
    /**
     * 
     * @type {string}
     * @memberof GetProceedingWithPartiesDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof GetProceedingWithPartiesDto
     */
    checkingProductId: string;
    /**
     * 
     * @type {GetInspectedProductDto}
     * @memberof GetProceedingWithPartiesDto
     */
    inspectedProduct: GetInspectedProductDto;
    /**
     * 
     * @type {string}
     * @memberof GetProceedingWithPartiesDto
     */
    actNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetProceedingWithPartiesDto
     */
    parties: string;
}

export function GetProceedingWithPartiesDtoFromJSON(json: any): GetProceedingWithPartiesDto {
    return GetProceedingWithPartiesDtoFromJSONTyped(json, false);
}

export function GetProceedingWithPartiesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProceedingWithPartiesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'legalBasis': GetDictionaryDtoFromJSON(json['legalBasis']),
        'quantity': json['quantity'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'approved': json['approved'],
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
        'sample': GetSampleDtoFromJSON(json['sample']),
        'inspectedProductId': json['inspectedProductId'],
        'checkingProductId': json['checkingProductId'],
        'inspectedProduct': GetInspectedProductDtoFromJSON(json['inspectedProduct']),
        'actNumber': json['actNumber'],
        'parties': json['parties'],
    };
}

export function GetProceedingWithPartiesDtoToJSON(value?: GetProceedingWithPartiesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'legalBasis': GetDictionaryDtoToJSON(value.legalBasis),
        'quantity': value.quantity,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'approved': value.approved,
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
        'sample': GetSampleDtoToJSON(value.sample),
        'inspectedProductId': value.inspectedProductId,
        'checkingProductId': value.checkingProductId,
        'inspectedProduct': GetInspectedProductDtoToJSON(value.inspectedProduct),
        'actNumber': value.actNumber,
        'parties': value.parties,
    };
}


