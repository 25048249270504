import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';

import { AssociateDocuments } from './AssociateDocumentsTypes';

export default <GetDto extends { id: string }, CreateUpdateDto>(props: {
  name: string;
  createModalType: React.ElementType;
  presentModalType: React.ElementType;
  docs: AssociateDocuments<GetDto, CreateUpdateDto>;
  initialBannerId?: string;
  className?: string;
  oneDocumentOnly?: boolean;
  oneDocumentShowChangeButton?: boolean;
  oneDocumentChangeButtonText?: string;
  readonly?: boolean;
  data?: string;
  align?: 'start' | 'end';
  onBannerClosed?: () => void;
  limitToValues?: string[];
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showPresentModal, setShowPresentModal] = useState(false);
  const [presentedDocument, setPresentedDocument] = useState<GetDto>();
  const showCreate =
    !props.oneDocumentOnly || props.docs.documents.length === 0;
  const createButtonText = showCreate
    ? 'Dodaj...'
    : props.oneDocumentChangeButtonText || 'Zmień...';
  const showCreateButton =
    !props.readonly && (showCreate || props.oneDocumentShowChangeButton);

  const createDocument = (doc: CreateUpdateDto) => {
    props.docs.add(doc);
    setShowCreateModal(false);
  };

  const deleteDocument = (id: string) => {
    props.docs.delete(id);
    setShowPresentModal(false);
  };

  const action = (handler: () => void) => {
    return {
      onClick: handler,
      onKeyPress: (event: { key: string }) => {
        if (event.key === ' ' || event.key === 'Enter') {
          handler();
        }
      },
    };
  };

  useEffect(() => {
    if (presentedDocument) {
      const refreshed = props.docs.documents.find(
        d => d.id === presentedDocument.id,
      );
      if (refreshed) {
        setPresentedDocument(refreshed);
      }
    }
  }, [props.docs, presentedDocument]);

  useEffect(() => {
    const doc = props.docs.documents.find(d => d.id === props.initialBannerId);
    if (doc) {
      setPresentedDocument(doc);
      setShowPresentModal(true);
    }
  }, [props.docs, props.initialBannerId]);

  return (
    <>
      <Dropdown
        className={['d-grid', props.className].join(' ')}
        align={props.align}
      >
        <Dropdown.Toggle
          variant="outline-success"
          disabled={props.readonly && props.docs.documents.length === 0}
        >
          {props.name}{' '}
          {props.docs.documents.length > 0 && (
            <Badge bg="success">{props.docs.documents.length}</Badge>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {showCreateButton && (
            <>
              <Dropdown.Item {...action(() => setShowCreateModal(true))}>
                {createButtonText}
              </Dropdown.Item>
              {props.docs.documents.length > 0 && <Dropdown.Divider />}
            </>
          )}
          {props.docs.documents.map(doc => (
            <Dropdown.Item
              key={doc.id}
              {...action(() => {
                setPresentedDocument(doc);
                setShowPresentModal(true);
              })}
              className="text-wrap max-width-33vw"
            >
              {props.docs.labelGenerator(doc)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <props.createModalType
        show={showCreateModal}
        newAssociate={props.docs.new}
        onGet={props.docs.get}
        onClose={() => setShowCreateModal(false)}
        onCreate={createDocument}
        validate={props.docs.validate}
        data={props.data}
        limitToValues={props.limitToValues}
      />
      <props.presentModalType
        show={showPresentModal}
        readonly={props.readonly}
        document={presentedDocument}
        validate={props.docs.validate}
        onGet={props.docs.get}
        onUpdate={props.docs.update}
        onApprove={props.docs.approve}
        onRevertApprove={props.docs.revertApprove}
        onDelete={deleteDocument}
        onClose={() => {
          setShowPresentModal(false);
          setPresentedDocument(undefined);
          props.onBannerClosed && props.onBannerClosed();
        }}
        data={props.data}
      />
    </>
  );
};
