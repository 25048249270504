/**
 * This file is shared between snrwb-core & snrwb-web
 * If you have to change it then do it in snrwb-core and run yarn get:validation-schemas @snrwb-web
 */
import * as yup from 'yup';

const digits = /^[0-9]+$/;

const dateNotFuture = (value: Date | null | undefined) => {
  if (!value) {
    return true;
  }

  const validating = new Date(value);
  validating.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return validating <= today;
};

const yearNotFuture = (value: number | null | undefined) => {
  if (!value) {
    return true;
  }

  const todayYear = new Date().getFullYear();

  return value <= todayYear;
};

const dateWeekAhead = (value: Date | null | undefined) => {
  if (!value) {
    return true;
  }

  const validating = new Date(value);
  validating.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return validating.valueOf() <= today.valueOf() + 7 * 24 * 60 * 60 * 1000;
};

const notEarlierThan = (otherDate: string) => {
  function compare(value: Date | undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const date = this.parent[otherDate];
    if (!date || !value || !(date instanceof Date)) {
      return true;
    }
    const thisDate = new Date(value).setHours(0, 0, 0, 0);
    const thatDate = new Date(date).setHours(0, 0, 0, 0);

    return thisDate >= thatDate;
  }
  return compare;
};

export const InspectionMetricSchema = yup.object().shape({
  organizationTypeId: yup
    .string()
    .nullable()
    .required('Proszę wskazać w jakiej roli występuje podmiot.'),
  inspectionTypeId: yup
    .string()
    .nullable()
    .required('Należy wskazać rodzaj kontroli.'),
  startDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Należy podać datę rozpoczęcia.')
    .test(
      'not future',
      'Data rozpoczęcia nie może być przyszła.',
      dateNotFuture,
    ),
  inspectionPlace: yup
    .string()
    .nullable()
    .required('Należy wskazać miejsce kontroli.'),
  fileNumber: yup.string().nullable().required('Proszę podać numer akt.'),
});

function validNip(nip: string | null | undefined) {
  if (typeof nip !== 'string') {
    return false;
  }

  nip = nip.replace(/[ -]/gi, '');
  if (nip.length !== 10) {
    return false;
  }
  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }
  return sum % 11 === controlNumber;
}

function digitsOnly(text: string | null | undefined) {
  if (!text) {
    return true;
  }

  if (!text.match(digits)) {
    return false;
  }
  return true;
}

function notEmptyOrSpace(text: string | null | undefined) {
  if (!text) {
    return false;
  }

  const tempText = text.replace(new RegExp(`\\s+`, 'g'), ' ').trim();

  if (!tempText || tempText.length === 0) {
    return false;
  }

  return true;
}

function validPostcode(postcode: string | null | undefined) {
  if (!postcode) {
    return false;
  }

  const match = postcode.match(
    /^(\d\d-\d\d\d)(\s)([A-ZŻŁĆŚŹŃ][-a-zżółćęśąźń ]+\s*)*$/,
  );
  if (!match) {
    return false;
  }
  return true;
}

export const NipSchema = yup.object().shape({
  nip: yup
    .string()
    .nullable()
    .required('Proszę podać numer NIP.')
    .test('valid nip', 'Podany NIP jest niepoprawny.', validNip)
    .test(
      'only digits in nip',
      'Numer NIP powinien się składać wyłącznie z cyfr.',
      digitsOnly,
    ),
});

export const OrganizationSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę podmiotu.'),
  country: yup.string().nullable().required('Proszę wskazać państwo.'),
  activityTypeId: yup.string().when('country', {
    is: 'Polska',
    then: yup.string().nullable().required('Należy określić typ działalności'),
    otherwise: yup.string().nullable(),
  }),
  nip: yup.string().when('country', {
    is: 'Polska',
    then: yup
      .string()
      .nullable()
      .test('valid nip', 'Podany NIP jest niepoprawny.', validNip)
      .test(
        'only digits in nip',
        'Numer NIP powinien się składać wyłącznie z cyfr.',
        digitsOnly,
      ),
    otherwise: yup.string().nullable(),
  }),
  postcode: yup.string().when('country', {
    is: 'Polska',
    then: yup
      .string()
      .nullable()
      .test(
        'valid postcode',
        'Poczta powinna być w formacie XX-XXX Miejscowość.',
        validPostcode,
      ),
    otherwise: yup.string().nullable(),
  }),
  place: yup.string().when('country', {
    is: 'Polska',
    then: yup
      .string()
      .nullable()
      .required('Proszę wprowadzić miejscowość związaną z podmiotem.'),
    otherwise: yup.string().nullable(),
  }),
  addressEx: yup.string().when('country', {
    is: 'Polska',
    then: yup.string().nullable(),
    otherwise: yup
      .string()
      .nullable()
      .required('Dla podmiotów zagranicznych należy podać adres.'),
  }),
  firstName: yup.string().when('activityTypeShort', {
    is: 'IDG',
    then: yup
      .string()
      .nullable()
      .required('Proszę podać imię osoby prowadzącej działalność gospodarczą.'),
    otherwise: yup.string().nullable(),
  }),
  lastName: yup.string().when('activityTypeShort', {
    is: 'IDG',
    then: yup
      .string()
      .nullable()
      .required(
        'Proszę podać nazwisko osoby prowadzącej działalność gospodarczą.',
      ),
    otherwise: yup.string().nullable(),
  }),
});

export const InspectionBasedOnMetricSchema = yup.object().shape({
  metric: InspectionMetricSchema,
});

export const InspectionBasedOnMetricWithOrganizationSchema = yup
  .object()
  .shape({
    metric: InspectionMetricSchema,
    organization: OrganizationSchema,
  });

export const InspectionSchema = yup.object().shape({
  signatureDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not future',
      'Data podpisania protokołu może być najdalej 7 dni od dzisiaj.',
      dateWeekAhead,
    )
    .test(
      'not earlier than end date',
      'Data podpisania nie może być wcześniejsza od daty zakończenia kontroli.',
      notEarlierThan('endDate'),
    ),
  endDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not future',
      'Data zakończenia kontroli nie może być przyszła.',
      dateNotFuture,
    ),
});

export const InspectionSigningSchema = yup.object().shape({
  endDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę zakończenia kontroli.')
    .test(
      'not future',
      'Data zakończenia kontroli nie może być przyszła.',
      dateNotFuture,
    ),
  signatureDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę podpisania protokołu.')
    .test(
      'not future',
      'Data podpisania protokołu nie może być przyszła.',
      dateNotFuture,
    )
    .test(
      'not earlier than end date',
      'Data podpisania nie może być wcześniejsza od daty zakończenia kontroli.',
      notEarlierThan('endDate'),
    ),
});

export const ProductTypeSchemaNoOrganization = yup.object().shape({
  nkitw: yup
    .string()
    .nullable()
    .when('description', {
      is: (description: string) =>
        !description || description.trim().length === 0,
      then: yup
        .string()
        .nullable()
        .test(
          'not empty',
          'Proszę wprowadzić niepowtarzalny kod identyfikacyjny typu wyrobu lub opis.',
          notEmptyOrSpace,
        ),
      otherwise: yup.string().nullable(),
    }),
  description: yup.string().nullable(),
  signId: yup
    .string()
    .nullable()
    .required('Konieczne jest wskazanie oznakowania.'),
  groupId: yup.string().nullable().required('Proszę podać grupę typu wyrobu.'),
});

export const ProductTypeSchema = ProductTypeSchemaNoOrganization.shape({
  organizationId: yup
    .string()
    .nullable()
    .required('Typ wyrobu musi mieć wskazanego producenta.'),
});

export const CreateWithNewProductTypeSchema = yup.object().shape({
  inspectionId: yup.string().nullable().required('Należy wskazać kontrolę.'),
  productType: ProductTypeSchema,
});

export const CreateWithNewProducerAndProductTypeSchema = yup.object().shape({
  inspectionId: yup.string().nullable().required('Należy wskazać kontrolę.'),
  producer: OrganizationSchema,
  productType: ProductTypeSchemaNoOrganization,
});

export const InspectedProductSchema = yup.object().shape({
  yearOfProduction: yup
    .number()
    .nullable()
    .typeError('Proszę wprowadzić poprawny rok produkcji.')
    .min(1990, 'Proszę wprowadzić poprawny rok produkcji.')
    .test(
      'not future',
      'Wprowadzony rok jest z przyszłości. Proszę wprowadzić poprawny rok produkcji.',
      yearNotFuture,
    ),
});

const basicSampleCollectSchema = {
  fileNumber: yup.string().nullable().required('Należy podać numer akt.'),
  collectBasisId: yup
    .string()
    .nullable()
    .required('Należy wskazać podstawę pobrania.'),
  collectPlace: yup
    .string()
    .nullable()
    .required('Proszę podać nazwę i adres miejsca pobrania.'),
  actionDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not future',
      'Data rozpoczęcia czynności nie może być przyszła.',
      dateNotFuture,
    )
    .when('collectBasisShort', {
      is: '25.1',
      then: yup
        .date()
        .transform(value => (isNaN(value) ? undefined : value))
        .nullable(),
      otherwise: yup
        .date()
        .transform(value => (isNaN(value) ? undefined : value))
        .required('Proszę podać datę rozpoczęcia czynności.'),
    }),
  signatureDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not future',
      'Data podpisania protokołu z czynności może być najdalej 7 dni od dzisiaj.',
      dateWeekAhead,
    )
    .when('collectBasisShort', {
      is: '25.1',
      then: yup
        .date()
        .transform(value => (isNaN(value) ? undefined : value))
        .nullable(),
      otherwise: yup
        .date()
        .transform(value => (isNaN(value) ? undefined : value))
        .test(
          'not earlier than action date',
          'Data podpisania protokołu z czynności nie może być wcześniejsza od daty rozpoczęcia czynności.',
          notEarlierThan('actionDate'),
        ),
    }),
};

export const SampleCollectSchema = yup.object().shape({
  ...basicSampleCollectSchema,
  organizationId: yup.string().nullable().required('Należy wskazać podmiot.'),
});

export const SampleCollectNoOrganizationSchema = yup
  .object()
  .shape(basicSampleCollectSchema);

export const SampleCollectSigningSchema = yup.object().shape({
  fileNumber: yup.string().nullable().required('Należy podać numer akt.'),
  collectBasisId: yup
    .string()
    .nullable()
    .required('Należy wskazać podstawę pobrania.'),
  actionDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .when('collectBasisShort', {
      is: '25.1',
      then: yup
        .date()
        .transform(value => (isNaN(value) ? undefined : value))
        .nullable(),
      otherwise: yup
        .date()
        .transform(value => (isNaN(value) ? undefined : value))
        .required('Proszę podać datę rozpoczęcia czynności.'),
    })
    .test(
      'not future',
      'Data rozpoczęcia czynności nie może być przyszła.',
      dateNotFuture,
    ),
  organizationId: yup.string().nullable().required('Należy wskazać podmiot.'),
  collectPlace: yup
    .string()
    .nullable()
    .required('Proszę podać nazwę i adres miejsca pobrania.'),
  examOrganizationalUnitId: yup
    .string()
    .nullable()
    .required('Organ musi być wskazany.'),
  signatureDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę podpisania protokołu.')
    .test(
      'not future',
      'Data podpisania protokołu nie może być przyszła.',
      dateNotFuture,
    ),
  placeOfSignature: yup
    .string()
    .nullable()
    .required('Proszę podać miejsce podpisania protokołu.'),
});

export const SampleExamSchema = yup.object().shape({
  fileNumber: yup.string().nullable().required('Należy podać numer akt.'),
  supplyDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test('not future', 'Data dostawy nie może być przyszła.', dateNotFuture),
  collectDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę pobrania.')
    .test('not future', 'Data pobrania nie może być przyszła.', dateNotFuture),
  sampleSignatureDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not future',
      'Data podpisania protokołu może być najdalej 7 dni od dzisiaj.',
      dateWeekAhead,
    ),
});

export const SampleExamCreateBasedOnProductTypeSchema = yup.object().shape({
  sampleCollectId: yup.string().nullable().required('Należy wskazać pobranie.'),
  productTypeId: yup.string().nullable().required('Należy wskazać typ wyrobu.'),
});

export const SampleExamCreateWithNewProductTypeSchema = yup.object().shape({
  sampleCollectId: yup.string().nullable().required('Należy wskazać pobranie.'),
  productType: ProductTypeSchemaNoOrganization,
});

export const SampleExamCreateWithOrganizationAndProductTypeSchema = yup
  .object()
  .shape({
    sampleCollectId: yup
      .string()
      .nullable()
      .required('Należy wskazać pobranie.'),
    productType: ProductTypeSchemaNoOrganization,
    producer: OrganizationSchema,
  });

export const SampleCollectCreateBasedOnInspectedProductSchema = yup
  .object()
  .shape({
    sampleCollectDto: SampleCollectSchema,
    inspectedProductId: yup
      .string()
      .nullable()
      .required('Należy wskazać kontrolowany wyrób.'),
  });

export const SampleCollectCreateWithNewOrganizatioSchema = yup.object().shape({
  sampleCollectDto: SampleCollectNoOrganizationSchema,
  organizationDto: OrganizationSchema,
});

export const DictionarySchema = yup.object().shape({
  value: yup.string().nullable().required('Proszę podać wartość słownika.'),
});

export const OrganizationalUnitSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę organu.'),
  shortname: yup.string().nullable().required('Proszę podać symbol.'),
});

export enum AttachmentObjectType {
  InspectedProduct = 'kontrolowany_wyrob',
  ProtocolDuringInspection = 'protokol_w_trakcie_kontroli',
  SampleCollect = 'pobranie_probek',
  Sample = 'probka',
  Kwz = 'kwz',
  ExamResult = 'wynik_badania',
  Judgment = 'orzeczenie',
  Proceeding = 'postepowanie',
  PostepowanieKarne = 'postepowanie_karne',
}

export const AttachmentSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę wskazać plik załącznika.'),
  typeId: yup.string().nullable().required('Należy wskazać rodzaj załącznika.'),
  enObjectType: yup
    .mixed<AttachmentObjectType>()
    .oneOf(Object.values(AttachmentObjectType))
    .required('Typ obiektu skojarzonego z załącznikiem musi być ustalony.'),
});

export const MisstatementSchema = yup.object().shape({
  varianceId: yup
    .string()
    .nullable()
    .required('Należy wskazać właściwą nieprawidłowość.'),
});

export const CorrectiveActionSchema = yup.object().shape({
  typeId: yup
    .string()
    .nullable()
    .required('Proszę wskazać rodzaj działania naprawczego.'),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę przeprowadzenia działania.')
    .test('not future', 'Data działania nie może być przyszła.', dateNotFuture),
  effective: yup
    .boolean()
    .required('Proszę określić czy działanie było skuteczne.'),
});

export const JudgmentSchema = yup.object().shape({
  key: yup.string().nullable().required('Należy wprowadzić znak orzeczenia.'),
  publishDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę wydania orzeczenia.')
    .test('not future', 'Data wydania nie może być przyszła.', dateNotFuture),
  kindId: yup.string().nullable().required('Należy wskazać rodzaj orzeczenia.'),
  organizationalUnitId: yup
    .string()
    .nullable()
    .required('Należy wskazać organ.'),
});

export const EmployeeSchema = yup.object().shape({
  firstName: yup.string().nullable().required('Proszę podać imię pracownika.'),
  lastName: yup
    .string()
    .nullable()
    .required('Proszę podać nazwisko pracownika.'),
  organizationalUnitId: yup
    .string()
    .nullable()
    .required('Należy wskazać organ.'),
});

export const ProtocolDuringInspectionSchema = yup.object().shape({
  number: yup
    .string()
    .nullable()
    .required('Proszę wprowadzić numer protokołu.'),
  typeId: yup.string().nullable().required('Proszę określić typ protokołu.'),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę protokołu.')
    .test('not future', 'Data protokołu nie może być przyszła.', dateNotFuture),
  signatureDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not earlier than date',
      'Data podpisania nie może być wcześniejsza od daty protokołu.',
      notEarlierThan('date'),
    )
    .test(
      'not future',
      'Data podpisania protokołu może być najdalej 7 dni od dzisiaj.',
      dateWeekAhead,
    ),
});

export const ProtocolDuringInspectionSigningSchema = yup.object().shape({
  number: yup
    .string()
    .nullable()
    .required('Proszę wprowadzić numer protokołu.'),
  typeId: yup.string().nullable().required('Proszę określić typ protokołu.'),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę protokołu.')
    .test('not future', 'Data protokołu nie może być przyszła.', dateNotFuture),
  signatureDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Konieczne jest wprowadzenie daty podpisania.')
    .test(
      'not future',
      'Data podpisania protokołu nie może być przyszła.',
      dateNotFuture,
    )
    .test(
      'not earlier than date',
      'Data podpisania nie może być wcześniejsza od daty protokołu.',
      notEarlierThan('date'),
    ),
  placeOfSignature: yup
    .string()
    .nullable()
    .required('Miejsce podpisania protokołu jest wymagane.'),
});

export const CertificateSchema = yup.object().shape({
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę.')
    .test('not future', 'Data nie może być przyszła.', dateNotFuture),
  organizationalUnitId: yup
    .string()
    .nullable()
    .required('Proszę określić jednostkę notyfikującą.'),
  groupProductTypeId: yup
    .string()
    .nullable()
    .required('Proszę określić grupę wyrobu budowlanego.'),
  informationId: yup
    .string()
    .nullable()
    .required('Proszę określić typ informacji.'),
});

export const CertificateWithOrganizationSchema = yup.object().shape({
  certificate: CertificateSchema,
  organization: OrganizationSchema,
});

export const InspectorSchema = yup.object().shape({
  authorization: yup
    .string()
    .nullable()
    .required('Proszę wprowadzić numer upoważnienia'),
  authorizationDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę wskazać datę upoważnienia.')
    .test(
      'not future',
      'Data upoważnienia nie może być przyszła.',
      dateNotFuture,
    ),
  employeeId: yup.string().nullable().required('Proszę wskazać pracownika.'),
  inspectionId: yup.string().when('sampleExamId', {
    is: (sampleExamId: string) => !sampleExamId,
    then: yup
      .string()
      .required('Kontroler musi być powiązany z kontrolą lub badaniem próbki.'),
    otherwise: yup.string().nullable(),
  }),
  sampleExamId: yup.string().nullable(),
});

export const SampleExamSigningSchema = yup.object().shape({
  fileNumber: yup
    .string()
    .nullable()
    .required('Proszę podać numer akt sprawy.'),
  protocolNumber: yup
    .string()
    .nullable()
    .required('Proszę podać numer protokołu.'),
  organizationTypeId: yup
    .string()
    .nullable()
    .required('Proszę wskazać miejsce pobrania.'),
  productTypeId: yup.string().nullable().required('Proszę wskazać typ wyrobu.'),
  securedQuantity: yup
    .string()
    .nullable()
    .required('Proszę określić wielkość partii.'),
  sampleSignatureDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę podpisania protokołu.')
    .test(
      'not future',
      'Data podpisania nie może być przyszła.',
      dateNotFuture,
    ),
  protocolPrintModeId: yup
    .string()
    .nullable()
    .required('Należy określić tryb sporządzenia protokołu.'),
  technicalSpecification: yup
    .string()
    .nullable()
    .required('Należy określić specyfikację techniczną.'),
  projectPOIiS: yup
    .boolean()
    .nullable()
    .required('Proszę określić czy próbki pobrano w ramach projektu UE.'),
});

export const SampleSchema = yup.object().shape({
  controlSample: yup
    .boolean()
    .nullable()
    .required('Typ próbki (kontrolna lub nie) musi być ustalony.'),
  productionDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test('not future', 'Data produkcji nie może być przyszła.', dateNotFuture),
  sampleCommissionDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test('not future', 'Data zlecenia nie może być przyszła.', dateNotFuture),
});

export enum InspectionPlanStatus {
  NotApproved = 'niezatwierdzony',
  ApprovedByWINB = 'zatwierdzony przez WINB',
  ApprovedByGINB = 'zatwierdzony przez GINB',
}

export const InspectionPlanSchema = yup.object().shape({
  year: yup
    .number()
    .typeError('Proszę wprowadzić poprawny rok.')
    .required('Proszę podać rok.')
    .min(
      1918,
      'Wprowadzony rok jest z przeszłości. Proszę wprowadzić poprawny rok.',
    )
    .max(
      2090,
      'Wprowadzony rok jest z przyszłości. Proszę wprowadzić poprawny rok.',
    ),
  numberOfProducts: yup
    .number()
    .min(0)
    .nullable(true)
    .transform((_, val) => (val === val ? val : null)),
  numberOfProductsCpr: yup
    .number()
    .min(0)
    .nullable(true)
    .max(
      yup.ref('numberOfProducts'),
      'Liczba kontrolowanych wyrobów CPR nie może być większa od liczby wszystkich kontrolowanych wyrobów',
    )
    .transform((_, val) => (val === val ? val : null)),
  numberOfInspections: yup
    .number()
    .min(0)
    .nullable(true)
    .transform((_, val) => (val === val ? val : null)),
  numberOfInspectionsCpr: yup
    .number()
    .min(0)
    .nullable(true)
    .max(
      yup.ref('numberOfInspections'),
      'Liczba kontroli CPR nie może być większa od liczby wszystkich kontroli',
    )
    .transform((_, val) => (val === val ? val : null)),
});

export const InspectionPlanGroupSchema = yup.object().shape({
  productGroupId: yup
    .string()
    .nullable()
    .required('Proszę wskazać grupę wyrobu.'),
  numberOfInspections: yup
    .number()
    .min(0)
    .nullable(true)
    .transform((_, val) => (val === val ? val : null)),
  numberOfInspectionsCpr: yup
    .number()
    .integer()
    .min(0)
    .nullable()
    .max(
      yup.ref('numberOfInspections'),
      'Liczba kontroli CPR nie może byc większa od liczby wszystkich kontroli',
    )
    .transform((_, val) => (val === val ? val : null)),
});

export const CustomsFeedbackSchema = yup.object().shape({
  receiptDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę wpływu.')
    .test('not future', 'Data wpływu nie może być przyszła.', dateNotFuture),
  name: yup.string().nullable().required('Proszę podać nazwę wyrobu.'),
  customsAuthority: yup
    .string()
    .nullable()
    .required('Proszę określić organ celny.'),
  constructionProduct: yup
    .boolean()
    .nullable()
    .required('Proszę określić, czy opinia dotyczy wyrobu budowlanego.'),
  positive: yup.boolean().when('constructionProduct', {
    is: (constructionProduct: boolean) => constructionProduct,
    then: yup
      .boolean()
      .nullable()
      .required('Proszę określić, czy opinia jest pozytywna.'),
    otherwise: yup.boolean().nullable(),
  }),
  releaseDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę wydania.')
    .test(
      'not future',
      'Data wydania opinii celnej nie może być przyszła.',
      dateNotFuture,
    )
    .test(
      'not earlier than receipt date',
      'Data wydania nie może być wcześniejsza od daty wpływu opinii celnej.',
      notEarlierThan('receiptDate'),
    ),
});

export const CustomsFeedbackWithOrganizationSchema = yup.object().shape({
  customsFeedback: CustomsFeedbackSchema,
  organization: OrganizationSchema,
});

export const EssentialFeatureSchema = yup.object().shape({
  sampleExamId: yup
    .string()
    .nullable()
    .required('Konieczne jest powiązanie z badaniem próbek.'),
  feature: yup.string().nullable().required('Proszę określić charakterystykę.'),
  functionalProperty: yup
    .string()
    .nullable()
    .required('Proszę podać właściwość użytkową.'),
  examMethod: yup.string().nullable().required('Proszę podać metodę badania.'),
});

export const ExamResultSchema = yup.object().shape({
  sampleId: yup
    .string()
    .nullable()
    .required('Konieczne jest powiązanie z próbką.'),
  negative: yup.boolean().required('Proszę wskazać wynik.'),
});

export const PartyWithOrganizationSchema = yup.object().shape({
  organization: OrganizationSchema,
});

export const ProceedingSchema = yup.object().shape({
  startDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Proszę podać datę wszczęcia.')
    .test(
      'not future',
      'Data wszczęcia postępowania nie może być przyszła.',
      dateNotFuture,
    ),
  endDate: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .test(
      'not future',
      'Data zakończenia postępowania nie może być przyszła.',
      dateNotFuture,
    )
    .test(
      'not earlier than start date',
      'Data zakończenia postępowania nie może być wcześniejsza od daty jego rozpoczęcia.',
      notEarlierThan('startDate'),
    ),
  organizationalUnitId: yup
    .string()
    .nullable()
    .required('Należy wskazać organ.'),
});

export enum TemplateType {
  InspectionProtocol = 'protokolKontroli',
  InventoryProtocol = 'protokolInwentaryzacji',
  ExaminationProtocol = 'protokolOgledzin',
  SampleExamProtocol = 'protokolPobraniaProbki',
  ExamActivityProtocol = 'protokolZCzynnosci',
  Kwzwb = 'kwzwb',
  ExamResult = 'wynikBadaniaProbki',
}

export const KwzSchema = yup.object().shape({
  decisionSignId: yup
    .string()
    .nullable()
    .when('approved', {
      is: (approved: boolean) => approved,
      then: yup
        .string()
        .nullable()
        .required('Proszę wybrać symbol decyzji (zakładka: Decyzja).'),
    }),
  productionCountry: yup
    .string()
    .nullable()
    .when('approved', {
      is: (approved: boolean) => approved,
      then: yup
        .string()
        .nullable()
        .required(
          'Proszę wypełnić pole Kraj wytworzenia wyrobu budowlanego (zakładka: Dane dodatkowe).',
        ),
    }),
});

export enum MemoSubjectType {
  Inspection = 'kontrola',
  SampleCollect = 'pobranie_probek',
  SampleExam = 'badanie_probek',
  Proceeding = 'postepowanie',
  Pak = 'pak',
  Kwz = 'kwz',
}

export const PrivateMemoSchema = yup.object().shape({
  recipientId: yup
    .string()
    .nullable()
    .required('Wiadomość musi być skierowana do kogoś.'),
  content: yup.string().nullable().required('Należy podać treść notatki.'),
});

export const PublicMemoSchema = yup.object().shape({
  content: yup.string().nullable().required('Należy podać treść notatki.'),
});

export enum ReportSourceDocumentType {
  Inspections = 'inspections',
  Exams = 'exams',
  Proceedings = 'proceedings',
}
