/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetKwzDto,
    GetKwzDtoFromJSON,
    GetKwzDtoFromJSONTyped,
    GetKwzDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetKwzHistoryDto
 */
export interface GetKwzHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof GetKwzHistoryDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetKwzHistoryDto
     */
    ts: Date;
    /**
     * 
     * @type {GetKwzDto}
     * @memberof GetKwzHistoryDto
     */
    kwz: GetKwzDto;
    /**
     * 
     * @type {string}
     * @memberof GetKwzHistoryDto
     */
    activity: string;
    /**
     * 
     * @type {Date}
     * @memberof GetKwzHistoryDto
     */
    date: Date;
}

export function GetKwzHistoryDtoFromJSON(json: any): GetKwzHistoryDto {
    return GetKwzHistoryDtoFromJSONTyped(json, false);
}

export function GetKwzHistoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetKwzHistoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ts': (new Date(json['ts'])),
        'kwz': GetKwzDtoFromJSON(json['kwz']),
        'activity': json['activity'],
        'date': (new Date(json['date'])),
    };
}

export function GetKwzHistoryDtoToJSON(value?: GetKwzHistoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ts': (value.ts.toISOString()),
        'kwz': GetKwzDtoToJSON(value.kwz),
        'activity': value.activity,
        'date': (value.date.toISOString()),
    };
}


