import { SnrwbCoreContextType } from '..';
import momencik from '../../momencik';
import { NotificationsProviderType } from '../../notifications';
import {
  GetProceedingDto,
  GetProceedingExtendedDto,
  CreateUpdateProceedingDto,
  CreateUpdatePartyToProceedingDto,
  CreateUpdateOrganizationDto,
  CreateUpdateProductTypeDto,
  ValidationStatus,
  GetProductTypeDto,
  CreateUpdateProceedingDtoFromJSON,
  CreateUpdateCorrectiveActionDto,
  CreateUpdateJudgmentDto,
  GetDictionaryDto,
  GetSampleExamDto,
} from '../autogenerated/snrwbApiClient';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import * as CorrectiveActionContext from '../../../common/snrwbCore/contexts/CorrectiveActionContext';
import * as JudgmentContext from '../../../common/snrwbCore/contexts/JudgmentContext';
import { proceedingForGrid } from '../../../snrwb/components/Proceedings/ProceedingsSearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import DictionaryStatic from '../../../snrwb/components/Dictionary/DictionaryStatic';

import { ObjectType } from './AttachmentContext';
import {
  AddInspectionFunc,
  handlerForAddInspection,
} from './InspectionContext';

export interface ProceedingContextInterface {
  getById: (id: string) => Promise<GetProceedingDto>;
  getByInspection: (inspectionId: string) => Promise<GetProceedingDto[]>;
  getBySampleExam: (sampleExamId: string) => Promise<GetProceedingDto[]>;
  getPendingForUser: () => Promise<GetProceedingExtendedDto[]>;
  getFinishedForUser: () => Promise<GetProceedingExtendedDto[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  createBasedOnInspectedProduct: (
    inspectedProductId: string,
  ) => Promise<GetProceedingDto>;
  update: (id: string, dto: CreateUpdateProceedingDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
  approve: (id: string) => Promise<void>;
  revertApprove: (id: string) => Promise<void>;
  mayBeApproved: (id: string) => Promise<ValidationStatus>;
  mayExamControlSample: (id: string) => Promise<ValidationStatus>;
  controlSampleExam: (id: string) => Promise<void>;
  revertControlSampleExam: (id: string) => Promise<void>;
}

export const ProceedingContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.proceedingControllerGet(id),
    getByInspection: (inspectionId: string) =>
      api.proceedingControllerGetByInspection(inspectionId),
    getBySampleExam: (sampleExamId: string) =>
      api.proceedingControllerGetBySampleExam(sampleExamId),
    getPendingForUser: () => api.proceedingControllerGetPendingForUser(),
    getFinishedForUser: () => api.proceedingControllerGetFinishedForUser(),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.proceedingControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(proceedingForGrid);
    },
    getAllCount: (filterText?: string) =>
      api.proceedingControllerGetAllCount(filterText || ''),
    createBasedOnInspectedProduct: (inspectedProductId: string) =>
      api.proceedingControllerCreateBasedOnMetric(inspectedProductId),
    update: (id: string, dto: CreateUpdateProceedingDto) =>
      api.proceedingControllerUpdate(id, dto),
    delete: (id: string) => api.proceedingControllerDelete(id),
    approve: (id: string) => api.proceedingControllerApprove(id),
    revertApprove: (id: string) => api.proceedingControllerRevertApprove(id),
    mayBeApproved: (id: string) => api.proceedingControllerMayBeApproved(id),
    mayExamControlSample: (id: string) =>
      api.proceedingControllerMayExamControlSample(id),
    controlSampleExam: (id: string) =>
      api.proceedingControllerControlSampleExam(id),
    revertControlSampleExam: (id: string) =>
      api.proceedingControllerRevertControlSampleExam(id),
  };
};

export const toCuDto = (getDto: GetProceedingDto) => {
  const dto = CreateUpdateProceedingDtoFromJSON(getDto);
  if (getDto.legalBasis) {
    dto.legalBasisId = getDto.legalBasis.id;
  }
  if (getDto.organizationalUnit) {
    dto.organizationalUnitId = getDto.organizationalUnit.id;
  }
  if (getDto.sample) {
    dto.sampleId = getDto.sample.id;
  }

  return dto;
};

export const detailsPresent = (proceeding: GetProceedingDto) => {
  const form: { name: string; value: string | JSX.Element }[] = [];

  if (proceeding.startDate) {
    form.push({
      name: 'Data wszczęcia',
      value: momencik(proceeding.startDate),
    });
  }

  if (proceeding.endDate) {
    form.push({
      name: 'Data zakończenia',
      value: momencik(proceeding.endDate),
    });
  }

  if (proceeding.legalBasis) {
    form.push({
      name: 'Podstawa prawna',
      value: DictionaryStatic({ value: proceeding.legalBasis }),
    });
  }

  if (proceeding.quantity) {
    form.push({
      name: 'Ilość wyrobu',
      value: proceeding.quantity,
    });
  }

  return form;
};

export const forProceedingView = async (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  id: string,
  action: () => void,
) => {
  const proceeding = await snrwb.proceedings.getById(id);
  const parties = await snrwb.partiesToProceedings.getByProceeding(id);
  const inspectedProduct = await snrwb.inspectedProducts.getById(
    proceeding.inspectedProductId,
  );
  const checkingProduct = proceeding.checkingProductId
    ? await snrwb.inspectedProducts.getById(proceeding.checkingProductId)
    : undefined;
  const attachments = await snrwb.attachments.getAllForObject(
    ObjectType.Proceeding,
    id,
  );
  const correctiveActions = await snrwb.correctiveActions.getByProceeding(id);
  const judgments = await snrwb.judgments.getByProceeding(id);

  const judgmentTypes = await snrwb.dictionaries.getByDictionaryType(
    'orzeczenie - typ',
  );
  const tDecision = judgmentTypes.filter(jt => jt.shortname === 'decyzja')[0];
  const tRepair = judgmentTypes.filter(jt => jt.shortname === 'naprawcze')[0];
  const tCodex = judgmentTypes.filter(jt => jt.shortname === 'kodeks')[0];

  const isChild = (child: GetDictionaryDto, parent: GetDictionaryDto) => {
    let node = child;
    while (node.parentId) {
      node = judgmentTypes.filter(ju => ju.id === node.parentId)[0];
    }
    return node.id === parent.id;
  };

  const decisions = judgments.filter(ju => isChild(ju.kind, tDecision));
  const repairs = judgments.filter(ju => isChild(ju.kind, tRepair));
  const codex = judgments.filter(ju => isChild(ju.kind, tCodex));

  const ac = AttachmentContext.forAssociatedDocuments;
  const ca = CorrectiveActionContext.forAssociatedDocuments;
  const ju = JudgmentContext.forAssociatedDocuments;

  const assocAttachments = ac(snrwb, notifications, attachments, action);
  const assocCorrective = ca(snrwb, notifications, correctiveActions, action);
  const assocDecisions = ju(snrwb, notifications, decisions, action);
  const assocRepairs = ju(snrwb, notifications, repairs, action);
  const assocCodex = ju(snrwb, notifications, codex, action);

  assocAttachments.new = () => {
    const attachment = AttachmentContext.newAttachment();
    attachment.enObjectType = AttachmentContext.ObjectType.Proceeding;
    attachment.objectId = id;
    return attachment;
  };

  const crAdd = assocCorrective.add;
  assocCorrective.add = (dto: CreateUpdateCorrectiveActionDto) => {
    dto.proceedingId = id;
    crAdd(dto);
  };

  const juAdd = assocDecisions.add;
  const juAddWrapped = (dto: CreateUpdateJudgmentDto) => {
    dto.proceedingId = id;
    juAdd(dto);
  };
  assocDecisions.add = juAddWrapped;
  assocRepairs.add = juAddWrapped;
  assocCodex.add = juAddWrapped;

  const paks = await snrwb.paks.getByProceeding(id);

  let sampleExam: GetSampleExamDto | undefined = undefined;
  try {
    sampleExam = await snrwb.sampleExams.getByInspectedProduct(
      inspectedProduct.id,
    );
  } catch {
    // its ok
  }

  return {
    proceeding,
    parties,
    inspectedProduct,
    checkingProduct,
    paks,
    sampleExam,
    attachments: assocAttachments,
    correctiveActions: assocCorrective,
    decisions: assocDecisions,
    repairs: assocRepairs,
    codex: assocCodex,
  };
};

export interface ProceedingViewApi {
  changeProceeding: (dto: CreateUpdateProceedingDto) => void;
  approveProceeding: () => void;
  revertApproveProceeding: () => void;
  mayProceedingBeApproved: () => Promise<ValidationStatus>;
  deleteProceeding: () => void;
  addParty: (dto: CreateUpdatePartyToProceedingDto) => void;
  addPartyWithOrganization: (
    orgDto: CreateUpdateOrganizationDto,
    dto: CreateUpdatePartyToProceedingDto,
  ) => void;
  validateNewParty: (
    dto: CreateUpdatePartyToProceedingDto,
  ) => Promise<ValidationStatus>;
  deleteParty: (id: string) => void;
  changeProductType: (productType: CreateUpdateProductTypeDto) => void;
  approveProductType: (status: ValidationStatus) => void;
  revertApproveProductType: () => void;
  mayProductTypeBeApproved: (id: string) => Promise<ValidationStatus>;
  addInspection: AddInspectionFunc;
  mayExamControlSample: () => Promise<ValidationStatus>;
  controlSampleExam: () => void;
  addPak: (organizationId: string) => void;
  createKwz: (judgmentId: string) => void;
  deleteKwz: (judgmentId: string) => void;
  refresh: () => void;
}

export const handlersForProceedingView = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  proceeding: GetProceedingDto,
  productType: GetProductTypeDto,
  refreshAction: () => void,
  deleteAction: () => void,
  inspectionAction: (id: string) => void,
  pakAction: (id: string) => void,
) => {
  return {
    changeProceeding: (dto: CreateUpdateProceedingDto) =>
      notifications.onPromise(
        snrwb.proceedings.update(proceeding.id, dto),
        refreshAction,
      ),

    approveProceeding: () =>
      notifications.onPromise(
        snrwb.proceedings.approve(proceeding.id),
        refreshAction,
      ),

    revertApproveProceeding: () =>
      notifications.onPromise(
        snrwb.proceedings.revertApprove(proceeding.id),
        refreshAction,
      ),

    mayProceedingBeApproved: () =>
      snrwb.proceedings.mayBeApproved(proceeding.id),

    deleteProceeding: () =>
      notifications.onPromise(
        snrwb.proceedings.delete(proceeding.id),
        deleteAction,
        () => {
          // do nothing
        },
      ),

    addParty: (dto: CreateUpdatePartyToProceedingDto) =>
      notifications.onPromise(
        (async () => {
          await snrwb.partiesToProceedings.create(dto);
        })(),
        refreshAction,
      ),

    addPartyWithOrganization: (
      orgDto: CreateUpdateOrganizationDto,
      dto: CreateUpdatePartyToProceedingDto,
    ) =>
      notifications.onPromise(
        (async () => {
          await snrwb.partiesToProceedings.createWithOrganization(orgDto, dto);
        })(),
        refreshAction,
      ),

    validateNewParty: (dto: CreateUpdatePartyToProceedingDto) =>
      snrwb.partiesToProceedings.validateNew(dto),

    deleteParty: (id: string) =>
      notifications.onPromise(
        snrwb.partiesToProceedings.delete(id),
        refreshAction,
      ),

    changeProductType: (dto: CreateUpdateProductTypeDto) =>
      notifications.onPromise(
        snrwb.productTypes.update(productType.id, dto),
        refreshAction,
      ),

    approveProductType: (status: ValidationStatus) =>
      notifications.onPromise(
        snrwb.productTypes.approve(productType.id, status),
        refreshAction,
      ),

    revertApproveProductType: () => {
      notifications.onPromise(
        snrwb.productTypes.revertApprove(productType.id),
        refreshAction,
      );
    },

    mayProductTypeBeApproved: (id: string) =>
      snrwb.productTypes.mayBeApproved(id),

    addInspection: handlerForAddInspection(snrwb, inspectionAction),

    mayExamControlSample: () =>
      snrwb.proceedings.mayExamControlSample(proceeding.id),

    controlSampleExam: () =>
      notifications.onPromise(
        snrwb.proceedings.controlSampleExam(proceeding.id),
        refreshAction,
      ),

    addPak: (organizationId: string) =>
      snrwb.paks
        .createBasedOnProceeding(proceeding.id, organizationId)
        .then(pak => pakAction(pak.id)),

    createKwz: (judgmentId: string) =>
      notifications.onPromise(
        (async () => {
          snrwb.kwzs.createWithJudgment(judgmentId);
        })(),
        refreshAction,
      ),

    deleteKwz: (judgmentId: string) =>
      notifications.onPromise(
        snrwb.kwzs.deleteWithJudgment(judgmentId),
        refreshAction,
      ),

    refresh: refreshAction,
  };
};
