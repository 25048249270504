import React, { useState, useEffect, useRef } from 'react';

import { useAuth } from '../hooks/useAuth';
import { Waiting } from '../components/Waiting/Waiting';
import { useNotifications } from '../hooks/useNotifications';

import { Configuration, RequestOpts } from './autogenerated/snrwbApiClient';
import { DefaultApi } from './autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CertificateContext,
  CertificateContextInterface,
} from './contexts/CertificateContext';
import {
  DictionaryContext,
  DictionaryContextInterface,
} from './contexts/DictionaryContext';
import {
  DictionaryTypeContext,
  DictionaryTypeContextInterface,
} from './contexts/DictionaryTypeContext';
import {
  OrganizationContext,
  OrganizationContextInterface,
} from './contexts/OrganizationContext';
import {
  OrganizationalUnitContext,
  OrganizationalUnitContextInterface,
} from './contexts/OrganizationalUnitContext';
import {
  ProductTypeContext,
  ProductTypeContextInterface,
} from './contexts/ProductTypeContext';
import {
  InspectionContext,
  InspectionContextInterface,
} from './contexts/InspectionContext';
import {
  InspectionMetricContext,
  InspectionMetricContextInterface,
} from './contexts/InspectionMetricContext';
import {
  ReportContext,
  ReportContextInterface,
} from './contexts/ReportContext';
import {
  InspectedProductContext,
  InspectedProductContextInterface,
} from './contexts/InspectedProductContext';
import {
  EmployeeContext,
  EmployeeContextInterface,
} from './contexts/EmployeeContext';
import {
  PrintoutContext,
  PrintoutContextInterface,
} from './contexts/PrintoutContext';
import {
  AttachmentContext,
  AttachmentContextInterface,
} from './contexts/AttachmentContext';
import {
  MisstatementContext,
  MisstatementContextInterface,
} from './contexts/MisstatementContext';
import {
  CorrectiveActionContext,
  CorrectiveActionContextInterface,
} from './contexts/CorrectiveActionContext';
import {
  JudgmentContext,
  JudgmentContextInterface,
} from './contexts/JudgmentContext';
import {
  ProtocolDuringInspectionContext,
  ProtocolDuringInspectionContextInterface,
} from './contexts/ProtocolDuringInspectionContext';
import {
  InspectionPlanContext,
  InspectionPlanInterface,
} from './contexts/InspectionPlanContext';
import {
  InspectorContext,
  InspectorContextInterface,
} from './contexts/InspectorContext';
import {
  SampleCollectContext,
  SampleCollectContextInterface,
} from './contexts/SampleCollectContext';
import {
  SampleExamContext,
  SampleExamContextInterface,
} from './contexts/SampleExamContext';
import {
  SampleContext,
  SampleContextInterface,
} from './contexts/SampleContext';
import {
  CustomsFeedbackContext,
  CustomsFeedbackContextInterface,
} from './contexts/CustomsFeedbackContext';
import {
  EssentialFeatureContext,
  EssentialFeatureContextInterface,
} from './contexts/EssentialFeatureContext';
import {
  ExamResultContext,
  ExamResultContextInterface,
} from './contexts/ExamResultContext';
import {
  ExamResultHistoryContext,
  ExamResultHistoryContextInterface,
} from './contexts/ExamResultHistoryContext';
import {
  InspectionPlanGroupContext,
  InspectionPlanGroupInterface,
} from './contexts/InspectionPlanGroupContext';
import { PakContext, PakContextInterface } from './contexts/PakContext';
import {
  PartyToProceedingContext,
  PartyToProceedingContextInterface,
} from './contexts/PartyToProceedingContext';
import {
  ProceedingContext,
  ProceedingContextInterface,
} from './contexts/ProceedingContext';
import {
  DocumentationContext,
  DocumentationContextInterface,
} from './contexts/DocumentationContext';
import { KwzContext, KwzContextInterface } from './contexts/KwzContext';
import {
  ObjectHistoryContext,
  ObjectHistoryContextInterface,
} from './contexts/ObjectHistoryContext';
import { MemoContext, MemoContextInterface } from './contexts/MemoContext';
import {
  GusApiContext,
  GusApiContextInterface,
} from './contexts/GusApiContext';

export interface SnrwbCoreContextType {
  certificates: CertificateContextInterface;
  customsFeedbacks: CustomsFeedbackContextInterface;
  dictionaries: DictionaryContextInterface;
  dictionariesTypes: DictionaryTypeContextInterface;
  organizations: OrganizationContextInterface;
  organizationalUnits: OrganizationalUnitContextInterface;
  productTypes: ProductTypeContextInterface;
  inspections: InspectionContextInterface;
  inspectionMetrics: InspectionMetricContextInterface;
  reports: ReportContextInterface;
  inspectedProducts: InspectedProductContextInterface;
  employees: EmployeeContextInterface;
  printouts: PrintoutContextInterface;
  attachments: AttachmentContextInterface;
  misstatements: MisstatementContextInterface;
  correctiveActions: CorrectiveActionContextInterface;
  judgments: JudgmentContextInterface;
  protocolsDuringInspection: ProtocolDuringInspectionContextInterface;
  inspectionPlans: InspectionPlanInterface;
  inspectionPlanGroups: InspectionPlanGroupInterface;
  inspectors: InspectorContextInterface;
  sampleCollects: SampleCollectContextInterface;
  sampleExams: SampleExamContextInterface;
  samples: SampleContextInterface;
  essentialFeatures: EssentialFeatureContextInterface;
  examResults: ExamResultContextInterface;
  examResultsHistory: ExamResultHistoryContextInterface;
  paks: PakContextInterface;
  partiesToProceedings: PartyToProceedingContextInterface;
  proceedings: ProceedingContextInterface;
  docs: DocumentationContextInterface;
  kwzs: KwzContextInterface;
  objectHistory: ObjectHistoryContextInterface;
  memos: MemoContextInterface;
  organizationalUnitId: string | undefined;
  gusApi: GusApiContextInterface;
  setOrganizationalUnitId: (unitId: string) => void;
  registerConnectionHandler: (ensureConnected: () => Promise<void>) => void;
}

const getContext: (
  api: DefaultApi,
  organizationalUnitId: string | undefined,
  organizationalUnitSetter: (unitId: string) => void,
  registerConnectionHandler: (ensureConnected: () => Promise<void>) => void,
) => SnrwbCoreContextType = (
  api,
  organizationalUnitId,
  organizationalUnitSetter,
  registerConnectionHandler,
) => {
  return {
    certificates: CertificateContext(api),
    customsFeedbacks: CustomsFeedbackContext(api),
    dictionaries: DictionaryContext(api),
    dictionariesTypes: DictionaryTypeContext(api),
    organizations: OrganizationContext(api),
    organizationalUnits: OrganizationalUnitContext(api),
    productTypes: ProductTypeContext(api),
    inspections: InspectionContext(api),
    inspectionMetrics: InspectionMetricContext(api),
    reports: ReportContext(api),
    inspectedProducts: InspectedProductContext(api),
    employees: EmployeeContext(api),
    printouts: PrintoutContext(api),
    attachments: AttachmentContext(api),
    misstatements: MisstatementContext(api),
    correctiveActions: CorrectiveActionContext(api),
    judgments: JudgmentContext(api),
    protocolsDuringInspection: ProtocolDuringInspectionContext(api),
    inspectionPlans: InspectionPlanContext(api),
    inspectionPlanGroups: InspectionPlanGroupContext(api),
    inspectors: InspectorContext(api),
    sampleCollects: SampleCollectContext(api),
    sampleExams: SampleExamContext(api),
    samples: SampleContext(api),
    essentialFeatures: EssentialFeatureContext(api),
    examResults: ExamResultContext(api),
    examResultsHistory: ExamResultHistoryContext(api),
    paks: PakContext(api),
    partiesToProceedings: PartyToProceedingContext(api),
    proceedings: ProceedingContext(api),
    docs: DocumentationContext(api),
    kwzs: KwzContext(api),
    objectHistory: ObjectHistoryContext(api),
    memos: MemoContext(api),
    gusApi: GusApiContext(api),
    organizationalUnitId,
    setOrganizationalUnitId: organizationalUnitSetter,
    registerConnectionHandler,
  };
};

const notReadyApi = new DefaultApi();
const notReadyHandler = () => {
  // to be set later
};

export const SnrwbCoreContext = React.createContext(
  getContext(notReadyApi, undefined, notReadyHandler, notReadyHandler),
);

class APIWithHandler extends DefaultApi {
  constructor(
    configuration: Configuration,
    private responseExceptionHandler: (response: Response) => Response,
    private runExclusive: (callback: () => Promise<void>) => Promise<void>,
    private ensureConnected: () => Promise<void>,
  ) {
    super(configuration);
  }

  protected async request(context: RequestOpts): Promise<Response> {
    await this.ensureConnected();
    let response = new Response();
    await this.runExclusive(async () => {
      const token = localStorage.getItem('mzt-token') || undefined;
      context.headers['Authorization'] = 'Bearer ' + token;
      response = await super.request(context).catch(response => {
        return this.responseExceptionHandler(response);
      });
    });
    return response;
  }
}

export const SnrwbCoreProvider: React.FC = ({ children }) => {
  const auth = useAuth();
  const notifications = useNotifications();
  const [organizationalUnitId, setOrganizationalUnitId] = useState<string>();
  const [context, setDefaultContext] = useState(
    getContext(
      notReadyApi,
      organizationalUnitId,
      notReadyHandler,
      notReadyHandler,
    ),
  );
  const waitingRoomRef = useRef<() => Promise<void>>();

  useEffect(() => {
    const configuration = new Configuration({
      basePath: process.env.REACT_APP_SNRWBAPI_URL,
      ...(organizationalUnitId && {
        headers: {
          'x-organizational-unit': organizationalUnitId,
        },
      }),
    });

    const handler = (response: Response) => {
      if (response.status === 403) {
        notifications.unauthorized();
      }
      throw response;
    };

    const mutex = async (callback: () => Promise<void>) => {
      if (!auth.mutex) {
        throw new Error('No mutex in context!');
      }
      await auth.ensureTokenIsValid();
      return await auth.mutex.runExclusive(callback);
    };

    setDefaultContext(
      getContext(
        new APIWithHandler(configuration, handler, mutex, async () => {
          if (waitingRoomRef.current) {
            await waitingRoomRef.current();
          }
        }),
        organizationalUnitId,
        setOrganizationalUnitId,
        ensureConnected => (waitingRoomRef.current = ensureConnected),
      ),
    );
  }, [auth, notifications, organizationalUnitId]);

  return (
    <SnrwbCoreContext.Provider value={context}>
      {auth.mutex ? children : <Waiting />}
    </SnrwbCoreContext.Provider>
  );
};
